/* // Override default variables before the import
// you can open the bootstrap.scss module and check any available variables. */
// these are the main colors use only these 4
$primary: #2bbef9;
$secondary: #ed174a;
$light: #ffffff;
$accent: #4b8a68;
// not these

$success: #83e965;
$info: #4097b0;
$warning: #b8c924;
$danger: #d62518;

$dark: #343a40;
$blue: #26abff;
$facebook: #1877f2;
$gmail: #ea4335;
$gray: #e4ebf0;

/* //custom color to be mapped */

/* 
// this is used to map colors. change accordingly. */
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  blue: $blue,
  facebook: $facebook,
  gmail: $gmail,
  gray: $gray,
  /* // add any additional color below */ accent: $accent,
  /* // now bg-accent,btn-accent,etc.. can be called */
);

/* // Import Bootstrap and its default variables */
@import "~bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap/scss/bootstrap";



