.adminProducts {
  display: flex;
  width: 100%;
  .adminListContainer {
    flex: 6;
  }
}

.adminViewProducts {
  display: flex;
  width: 100%;
  .adminListContainer {
    flex: 6;
    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;
    }
    .left {
      margin-right: 20px;
      .file {
        margin-top: 10px;
      }
    }

    .left img {
      width: 200px;
      height: auto;
    }

    .right {
      display: flex;
      flex-direction: column;
    }

    .rightTop {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .rightTopLeft h2 {
      margin: 0;
    }

    .rightTopLeft p {
      margin: 0;
      color: #999999;
    }

    .rightTopRight {
      display: flex;
      flex-direction: column;
    }

    .rightTopRightInfo {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .rightTopRightInfo h3 {
      margin: 0;
    }

    .rightTopRightInfo span {
      color: #999999;
    }

    .rightBottom {
      margin-top: 20px;
    }

    .formLeft {
      display: flex;
      flex-direction: column;
    }

    .formLeft label {
      margin-bottom: 5px;
    }

    .formLeft input,
    .formLeft select {
      margin-bottom: 10px;
      padding: 5px;
    }

    .formRight {
      display: flex;
      flex-direction: column;
    }

    .formRightTop {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .formRightTopLeft input {
      width: 80%;
    }

    .formRightTopRight input {
      width: 80%;
    }

    .formRightTopLeft label,
    .formRightTopRight label {
      margin-bottom: 5px;
    }

    .formRightBottom {
      display: flex;
    }

    .formRightBottomLeft select,
    .formRightBottomRight select {
      margin-bottom: 10px;
      padding: 5px;
    }

    .formImage {
      margin-bottom: 20px;
    }

    .formImage label {
      margin-bottom: 5px;
    }

    button {
      padding: 10px 20px;
      background-color: #4caf50;
      color: white;
      border: none;
      cursor: pointer;
    }

    button:hover {
      background-color: #45a049;
    }

    button:focus {
      outline: none;
    }
  }
}
