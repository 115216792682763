.App {
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
}
.model-styles {
  font-family: "Montserrat", sans-serif;
}

.required:after {
  content: " *";
  color: red;
}
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.bg-facebook {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #1da1f2;
}
.bg-google {
  background-color: #ea4335;
}
.bg-instagram {
  background-color: #e1306c;
}
.bs-cover {
  background-size: cover;
}
.r-0 {
  right: 0;
}
.mw-180 {
  max-width: 180px;
}
.mw-140 {
  max-width: 140px;
}
.i-va {
  vertical-align: -0.125em;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.nav-item {
  font-weight: bold;
}
.nav-link-items:hover {
  color: #f8a41f;
}
.nav-link.dropdown-toggle::after {
  /* Hide the default down arrow */
  display: none;
}
.nav-list li {
  text-decoration: none;
}
.mobile-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}
