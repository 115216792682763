@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap");
.required:after {
  content: " *";
  color: red;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.bg-facebook {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #1da1f2;
}
.bg-google {
  background-color: #ea4335;
}
.bg-instagram {
  background-color: #e1306c;
}

.bs-cover {
  background-size: cover;
}

.r-0 {
  right: 0;
}
.mw-180 {
  max-width: 180px;
}
.mw-140 {
  max-width: 140px;
}
.i-va {
  vertical-align: -0.125em;
}

.footer {
  background-color: #f8a41f;
  width: 100%;
  text-align: center;

  font-weight: bold;
  font-size: 16px;
  padding: 50px;
}
.footer ul.footer-links li {
  text-decoration: none;
}

.footer ul.footer-links li::before {
  content: none;
}

.footer ul.footer-links {
  list-style-type: none;
  padding: 0;
}
/* 
.footer ul.footer-links li {
  display: inline-block;
  margin-right: 10px;
} */

/* .footer ul.footer-links li:last-child {
  margin-right: 0;
} */

@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
  width: 100%;
  height: 100%;
  background-color: gainsboro;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}

/* Eye icon */
.mt-n1 {
  margin-left: -30px;
  cursor: pointer;
  margin-top: 8px;
  width: 20px;
  height: 20px;
}
.dropdown-item {
  color: #5d3fd3;
}
.text {
  display: block;
  width: 100 px;

  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}

.zoom {
  padding: 5px;

  transition: transform 0.2s;

  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.25); /* IE 9 */
  -webkit-transform: scale(1.25); /* Safari 3-8 */
  transform: scale(1.25);
}

.zoomproduct {
  padding: 5px;

  transition: transform 0.2s;

  margin: 0 auto;
}

.zoomproduct:hover {
  -ms-transform: scale(1.25); /* IE 9 */
  -webkit-transform: scale(1.25); /* Safari 3-8 */
  transform: scale(1.25);
}
.Search {
  width: 400px;
  margin: 0 auto;
  position: relative;
  display: flex;
}
.SearchSpan {
  width: 15%;
  border: 1px solid #1c76d2;
  background: #1c76d2;
  padding-top: 4px;
  text-align: center;
  color: #fff;
  border-radius: 5px 0 0 5px;
  font-size: 20px;
}
.SearchInput {
  width: 85%;
  border: 3px solid #1c76d2;
  border-left: none;
  padding: 5px;
  border-radius: 0 5px 5px 0;
  outline: none;
}

.animation {
  display: block;
  margin: auto;
  border-color: red;
  height: 50vh;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 12px;
}

body {
  margin: 20px 0;
  padding: 0;
  /* font-family: "Montserrat", sans-serif; */
  overflow: scroll;
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-radius: 4px;
}

a {
  text-decoration: none;
  color: #333333;
}

a:hover {
  color: #f58551;
}

button {
  background-color: #16cc9b;
  border: 2px solid #16cc9b;
  color: #ffffff;
  transition: all 0.25s linear;
  cursor: pointer;
}

button::after {
  position: relative;
  right: 0;
  /* content: " \276f"; */
  transition: all 0.15s linear;
}

button:hover {
  background-color: #f58551;
  border-color: #f58551;
}

button:hover::after {
  right: -5px;
}

button:focus {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

input {
  transition: all 0.25s linear;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input {
  outline: none;
}

.container {
  width: 90%;
  margin: 0 auto;
  overflow: auto;
}

/* --- HEADER --- */
header.container {
  margin-bottom: 1.5rem;
}

header .breadcrumb {
  color: #7d7d7d;
}

header .breadcrumb li {
  float: left;
  padding: 0 6px;
}

header .breadcrumb li:first-child {
  padding-left: 2px;
}

header .breadcrumb li:not(:last-child)::after {
  content: " \276f";
  padding-left: 8px;
}

header .count {
  float: right;
  color: #333333;
}

/* --- PRODUCT LIST --- */
.products {
  border-top: 1px solid #ddd;
}

.products > li {
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
}

.row-cart {
  position: relative;
  overflow: auto;
  width: 100%;
}

.col,
.quantity,
.remove {
  float: left;
}

.col.left {
  width: 70%;
}

.col.right {
  width: 30%;
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
}

.detail {
  padding: 0 0.5rem;
  line-height: 2.2rem;
}

.detail .name:hover {
  font-size: 1.25rem;
}

.detail .description {
  color: #7d7d7d;
  font-size: 1rem;
}

.detail .price {
  font-size: 1.5rem;
}

.quantity,
.remove {
  width: 50%;
  text-align: center;
}

.remove svg {
  width: 60px;
  height: 60px;
}

.quantity > input {
  display: inline-block;
  width: 60px;
  height: 60px;
  position: relative;
  left: calc(50% - 30px);
  background: #fff;
  border: 2px solid #ddd;
  color: #7f7f7f;
  text-align: center;
  font: 600 1.5rem Helvetica, Arial, sans-serif;
}

.quantity > input:hover,
.quantity > input:focus {
  border-color: #f58551;
}

.close {
  fill: #7d7d7d;
  transition: color 150ms linear, background-color 150ms linear,
    fill 150ms linear, 150ms opacity linear;
  cursor: pointer;
}

.close:hover {
  fill: #f58551;
}

/* --- SUMMARY --- */
.promotion,
.summary,
.checkout {
  float: left;
  width: 100%;
  margin-top: 1.5rem;
}

.promotion > label {
  float: left;
  width: 100%;
  margin-bottom: 1rem;
}

.promotion > input {
  float: left;
  width: 80%;
  font-size: 1rem;
  padding: 0.5rem 0 0.5rem 1.8rem;
  border: 2px solid #16cc9b;
  border-radius: 2rem 0 0 2rem;
}

.promotion:hover > input {
  border-color: #f58551;
}

.promotion > button {
  float: left;
  width: 20%;
  padding: 0.5rem 0;
  border-radius: 0 2rem 2rem 0;
}

.promotion:hover > button {
  border-color: #f58551;
  background-color: #f58551;
}

.promotion > button::after {
  content: "\276f";
  font-size: 1rem;
}

.summary {
  font-size: 1.2rem;
  text-align: right;
}

.summary ul li {
  padding: 0.5rem 0;
}

.summary ul li span {
  display: inline-block;
  width: 30%;
}

.summary ul li.total {
  font-weight: bold;
}

.checkout {
  text-align: right;
}

.checkout > button {
  font-size: 1.2rem;
  padding: 0.8rem 2.8rem;
  border-radius: 1.5rem;
}

.empty-product {
  text-align: center;
}

.empty-product > button {
  font-size: 1.3rem;
  padding: 10px 30px;
  border-radius: 5px;
}

/* --- SMALL SCREEN --- */
@media all and (max-width: 599px) {
  .thumbnail img {
    display: none;
  }

  .quantity > input {
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
  }

  .remove svg {
    width: 40px;
    height: 40px;
  }
  /* .smallScreenBlock {
    border: 5px solid blue;
  } */
}

/* --- MEDIUM & LARGE SCREEN --- */
@media all and (min-width: 600px) {
  html {
    font-size: 14px;
  }

  .container {
    width: 75%;
    max-width: 960px;
  }

  .thumbnail,
  .detail {
    float: left;
  }

  .thumbnail {
    width: 30%;
  }

  .detail {
    width: 65%;
  }

  .promotion,
  .summary {
    width: 50%;
  }

  .checkout {
    width: 100%;
  }

  .checkout,
  .summary {
    text-align: right;
  }
}

/* --- LARGE SCREEN --- */
@media all and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}
/*flashing*/
.hover13 figure:hover img {
  opacity: 1;
  -webkit-animation: flash 1.25s;
  animation: flash 1.25s;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}


.hover14 figure {
  position: relative;
}
.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover14 figure:hover::before {
  -webkit-animation: shine 0.6s;
  animation: shine 0.6s;
}
@-webkit-keyframes shine {
  100% {
    left: 180%;
  }
}
@keyframes shine {
  100% {
    left: 180%;
  }
}

/* Pen for profile image */
.profile__editIcon {
  position: relative;
  top: -35px;
  right: -50px;
  width: 25px;
  height: 25px;
  color: #ffffff;
  background: rgb(0, 252, 34);
  border-radius: 50%;
  /* padding: 1px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor: pointer;
}

/* Circle */
.hover15 figure {
  position: relative;
}
.hover15 figure::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.hover15 figure:hover::before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}
@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.google-btn {
  margin: 25px;
  width: 80%;
  min-width: 184px;
  max-width: 500px;
  height: 50px;
  background-color: #fcfcfc;
  border-radius: 50px;
  box-shadow: 0 3px 4px 0 #bddbef;
  cursor: pointer;

  /* user-select: none; */
  transition: all 400ms ease 0s;
}
.google-signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-btn .google-icon-wrapper {
  /* position: absolute; */
  margin-top: 1px;
  margin-left: 1px;
  width: 60px;
  height: 60px;
  border-radius: 2px;
}
.google-btn .google-icon-svg {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 25px;
  height: 25px;
  user-select: none;
}
.google-btn .btn-text {
  float: right;
  /* margin: 11px 14px 40px 40px; */
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.2px;
  user-select: none;
}
.google-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, 0.5);
  user-select: none;
}
.google-btn:active {
  box-shadow: 0 1px 1px #757575;
  background: #f8f8f8;
  color: #fff;
  user-select: none;
}

.deals-of-the-day {
  padding: 0px;
  margin: 0px;
}

.countdown-timer {
  display: flex;
  align-items: center;
}

.time-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 5px;
}

.time {
  font-size: 24px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  background-color: #ed174a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.time-label {
  font-size: 14px;
  text-transform: uppercase;
}

.time-separator {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 30px;
  margin-top: -20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  border: 2px solid #ed174a;
}

.product-card {
  border: none;
  width: 220px;
  height: 400px;
  margin: 10px;

  position: absolute; /* Add this line */
}

.product-card-carousel {
  border: none;
  width: 195px;
  height: 400px;
  margin: 0;
  margin-bottom: 10px;
  position: absolute; /* Add this line */
}

.product-card-mobile {
  border: none;
  width: 170px;
  height: 400px;
  margin: 0;
  margin-bottom: 10px;
  position: relative; /* Add this line */
}

.product-card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-card-carousel:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-container {
  height: 200px;
  overflow: hidden;
}

.product-card .card-img-top {
  object-fit: cover;
  height: 100%;
}

.product-card-carousel .card-img-top {
  object-fit: cover;
  height: 100%;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
}

.short-description {
  font-size: 12px;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute; /* Add this line */
  bottom: 0; /* Add this line */
  left: 0; /* Add this line */
  width: 100%; /* Add this line */
  padding: 5px; /* Add this line */
  background-color: rgba(255, 255, 255, 0.8); /* Add this line */
}

.price-container-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Add this line */
  bottom: 0; /* Add this line */
  left: 0; /* Add this line */
  width: 100%; /* Add this line */
  padding: 5px; /* Add this line */
  background-color: rgba(255, 255, 255, 0.8); /* Add this line */
}

.card-cart {
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.discounted-price {
  font-size: 25px;
  margin-right: 10px;
  font-weight: bold;
}

.no-original-price {
  font-size: 25px;
  color: gray;
  text-decoration: line-through;
}

.original-price {
  font-size: 25px;
  font-weight: bold;
}

.hot {
  border: 2px solid yellow;
}

.row.no-gutters [class*="col-"] {
  padding: 0;
}

.zoom-effect {
  overflow: hidden;
}

.zoom-effect img {
  transition: transform 0.3s ease;
}

.zoom-effect img:hover {
  transform: scale(1.2);
}

.card-product-list {
  text-align: center;
  padding: 10px;
  border-radius: 8px;
}

.card-product-list .image-container {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-product-list .product-name {
  font-size: 18px;
}

.card-product-list .card-subtitle {
  margin-top: 15px;
}

.card-product-list .price-container {
  margin-top: 10px;
}

.card-product-list .btn-group {
  margin-top: 15px;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translate(-2px, -2px);
  }
  25% {
    transform: translate(2px, -2px);
  }
  50% {
    transform: translate(2px, 2px);
  }
  75% {
    transform: translate(-2px, 2px);
  }
  100% {
    transform: translate(-2px, -2px);
  }
}

.animate {
  animation: pulse 1s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.topheader-links {
  color: black;
  font-size: 15px;
  font-weight: normal;
  text-decoration: none;
  /* on hover */

  /* text-decoration-none text-dark font-weight-normal */
}
.topheader-links:hover {
  color: #f8a41f;
}
